import React, { Suspense, useState } from "react";

import { openGithub } from "@utils/utils.service";
import { getIconPath } from "@utils/icons.service";
import { ReactSVG } from "react-svg";

/* import ProjectDetailsModal from "@components/projects/project-details/ProjectDetailsModal"; */

import Loading from "@components/ui/loading/Loading";

const ProjectDetailsModal = React.lazy(() =>
  import("@components/projects/project-details/ProjectDetailsModal")
);
import "@components/projects/project/Project.scss";

const Project = ({ project }) => {
  const { logo, title, titleLogo, website, app, intro, info } = project;

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  return (
    <>
      <div
        className="
          project-container"
      >
        <div className="title-container">
          <ReactSVG src={logo} className="app-icon" />
          {titleLogo && <ReactSVG src={titleLogo} className="title-icon" />}
          <div className="previewContainer">
            <img className="app-image" src={website} alt="iphone" />
            <Suspense fallback={<Loading small />}>
              <div className="imageContainer">
                <img className="app-image2" src={app} alt="iphone" />
                <div className="storeContainer">
                  <ReactSVG
                    src={getIconPath("github3")}
                    className="github-icon"
                    onClick={openGithub}
                  />
                  <button
                    className="details-button"
                    onClick={() => setIsDetailsModalOpen(true)}
                  >
                    <ReactSVG
                      src={getIconPath("file")}
                      className="details-icon"
                    />
                    Project Details
                  </button>
                </div>
              </div>
            </Suspense>
          </div>
          <div className="productContainer"></div>
          <div className="infoContainer">
            <p className="intro">{intro}</p>
            <p className="info">{info}</p>
            {/* <p className="info">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloribus voluptatum laborum quos natus modi animi quo rerum
              voluptate debitis perferendis.
            </p> */}
          </div>
        </div>
      </div>
      {isDetailsModalOpen && (
        <ProjectDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          project={project}
        />
      )}
    </>
  );
};

export default Project;
