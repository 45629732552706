import React from "react";

import InViewAnimation from "@components/in-view-animation/InViewAnimation";

import { ABOUT_ME_ARTICLES } from "@constants/data";
import ArticleCard from "@components/ui/card/article-card/ArticleCard";

import { getImagePath } from "@utils/images.service";
import "@components/about-me/article/AboutMeArticle.scss";

const AboutMeArticle = () => {
  return (
    <>
      <div className="about-me-article-container">
        <InViewAnimation>
          <img
            src={getImagePath("memoji")}
            alt="urg-memoji"
            className="memoji"
          />
        </InViewAnimation>
        <div className="article">
          <InViewAnimation>
            <h2 className="intro">
              Highly dedicated 4th year Computer Science student with a minor in
              Economics at Bahcesehir University.
            </h2>
          </InViewAnimation>
          <div className="information-container">
            {ABOUT_ME_ARTICLES.map((article, index) => (
              <ArticleCard
                key={index}
                title={article.title}
                text={article.text}
                image={article.image}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMeArticle;
