import React from "react";

import Title from "@components/ui/title/Title";
import ProjectCard from "@components/projects/project-card/ProjectCard";
import InViewAnimation from "@components/in-view-animation/InViewAnimation";

import { PROJECT_CARDS } from "@constants/data";

import "@components/projects/project-card/project-card-list/ProjectCardList.scss";

const ProjectCardList = () => {
  return (
    <div className="project-card-list-container">
      <Title title="Other Projects" />
      <p className="subtitle">Some other examples of my work.</p>
      <InViewAnimation>
        <div className="project-card-list">
          <div className="project-list-macbook">
            {PROJECT_CARDS.map((project) => {
              return <ProjectCard key={project.key} project={project} />;
            })}
          </div>
          <ProjectCard
            project={{
              key: "newsreactnative",
              title: "News App",
              reactNative: true,
              images: { count: 6, key: "news-rn" },
            }}
          />
        </div>
      </InViewAnimation>
    </div>
  );
};

export default ProjectCardList;
