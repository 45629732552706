import Logo from "@assets/images/logo-white.png";
import JSmug from "@assets/images/js-mug.png";
import JS from "@assets/images/js-illustration.png";
import Memoji from "@assets/images/memoji.png";
import Schumacher from "@assets/images/msc.png";
import Art from "@assets/images/art.png";
import Art2 from "@assets/images/art-2.png";
import CleanCode from "@assets/images/clean-code.png";
import Freud from "@assets/images/freud.png";
import SteveJobs from "@assets/images/steve-jobs.png";
import Macintosh1 from "@assets/images/macintosh1.png";
import Macintosh2 from "@assets/images/macintosh2.jpeg";
import Macintosh3 from "@assets/images/macintosh3.png";
import imac from "@assets/images/imac.png";
import Autoteks1 from "@assets/images/projects/autoteks/autoteks-1.png";
import Autoteks2 from "@assets/images/projects/autoteks/autoteks-2.png";
import Autoteks3 from "@assets/images/projects/autoteks/autoteks-3.png";
import Autoteks4 from "@assets/images/projects/autoteks/autoteks-4.png";
import Autoteks5 from "@assets/images/projects/autoteks/autoteks-5.png";
import Autoteks6 from "@assets/images/projects/autoteks/autoteks-6.png";
import Autoteks7 from "@assets/images/projects/autoteks/autoteks-7.png";
import Autoteks8 from "@assets/images/projects/autoteks/autoteks-8.png";
import Autoteks9 from "@assets/images/projects/autoteks/autoteks-9.png";
import AutoteksLanding1 from "@assets/images/projects/autoteks-landing/autoteks-landing-1.png";
import AutoteksLandingMac from "@assets/images/projects/autoteks-landing/autoteks-landing-mac.png";
import Diet1 from "@assets/images/projects/diet/diet1.png";
import Diet2 from "@assets/images/projects/diet/diet-2.png";
import Diet3 from "@assets/images/projects/diet/diet-3.png";
import Diet4 from "@assets/images/projects/diet/diet4.png";
import Diet5 from "@assets/images/projects/diet/diet5.png";
import Diet6 from "@assets/images/projects/diet/diet6.png";
import Diet7 from "@assets/images/projects/diet/diet7.png";
import Diet8 from "@assets/images/projects/diet/diet8.png";
import Diet9 from "@assets/images/projects/diet/diet9.png";
import Diet10 from "@assets/images/projects/diet/diet10.png";
import Coindom1Mac from "@assets/images/projects/coindom/coindom1-mac.png";
import Coindom2Mac from "@assets/images/projects/coindom/coindom2-mac.png";
import Coindom3 from "@assets/images/projects/coindom/coindom3.png";
import Coindom4 from "@assets/images/projects/coindom/coindom4.png";
import Coindom5 from "@assets/images/projects/coindom/coindom5.png";
import Coindom6 from "@assets/images/projects/coindom/coindom6.png";
import Coindom7 from "@assets/images/projects/coindom/coindom7.png";
import Coindom8 from "@assets/images/projects/coindom/coindom8.png";
import Coindom9 from "@assets/images/projects/coindom/coindom9.png";
import Coindom10 from "@assets/images/projects/coindom/coindom10.png";
import Coindom11 from "@assets/images/projects/coindom/coindom11.png";
import Coindom12 from "@assets/images/projects/coindom/coindom12.png";

import f1card from "@assets/images/projects/f1-images/ f1-mac.png";
import Formula11 from "@assets/images/projects/f1-images/f1-1.png";
import Formula12 from "@assets/images/projects/f1-images/f1-2.png";
import Formula13 from "@assets/images/projects/f1-images/f1-3.png";
import Formula14 from "@assets/images/projects/f1-images/f1-4.png";
import Formula15 from "@assets/images/projects/f1-images/f1-5.png";
import Formula16 from "@assets/images/projects/f1-images/f1-6.png";
import Formula17 from "@assets/images/projects/f1-images/f1-7.png";
import Formula18 from "@assets/images/projects/f1-images/f1-8.png";
import Formula19 from "@assets/images/projects/f1-images/f1-9.png";
import Formula110 from "@assets/images/projects/f1-images/f1-10.png";
import Formula111 from "@assets/images/projects/f1-images/f1-11.png";
import Formula112 from "@assets/images/projects/f1-images/f1-12.png";
import Formula113 from "@assets/images/projects/f1-images/f1-13.png";
import Formula114 from "@assets/images/projects/f1-images/f1-14.png";
import guessmynumber from "@assets/images/projects/guessmynumber/guessmynumber-mac.png";
import guessmynumber1 from "@assets/images/projects/guessmynumber/guessmynumber-1.png";
import guessmynumber2 from "@assets/images/projects/guessmynumber/guessmynumber-2.png";
import guessmynumber3 from "@assets/images/projects/guessmynumber/guessmynumber-3.png";
import hangman from "@assets/images/projects/hangman/hangman-mac.png";
import hangman1 from "@assets/images/projects/hangman/hangman-1.png";
import hangman2 from "@assets/images/projects/hangman/hangman-2.png";
import newsreact from "@assets/images/projects/news-react/news-mac.png";
import newsreact1 from "@assets/images/projects/news-react/news-1.png";
import newsreact2 from "@assets/images/projects/news-react/news-2.png";
import newsreact3 from "@assets/images/projects/news-react/news-3.png";
import newsreact4 from "@assets/images/projects/news-react/news-4.png";
import newsreact5 from "@assets/images/projects/news-react/news-5.png";
import newsreact6 from "@assets/images/projects/news-react/news-6.png";
import newsreact7 from "@assets/images/projects/news-react/news-7.png";
import newsreact8 from "@assets/images/projects/news-react/news-8.png";
import newsreact9 from "@assets/images/projects/news-react/news-9.png";
import newsreact10 from "@assets/images/projects/news-react/news-10.png";
import newsreactnative from "@assets/images/projects/news-rn/news-iphone.png";
import newsreactnative1 from "@assets/images/projects/news-rn/news-rn-1.png";
import newsreactnative2 from "@assets/images/projects/news-rn/news-rn-2.png";
import newsreactnative3 from "@assets/images/projects/news-rn/news-rn-3.png";
import newsreactnative4 from "@assets/images/projects/news-rn/news-rn-4.png";
import newsreactnative5 from "@assets/images/projects/news-rn/news-rn-5.png";
import newsreactnative6 from "@assets/images/projects/news-rn/news-rn-6.png";

class AppImages {
  static getImage(type) {
    switch (type) {
      case "logo":
        return Logo;
      case "memoji":
        return Memoji;
      case "js-mug":
        return JSmug;
      case "js":
        return JS;
      case "schumacher":
        return Schumacher;
      case "art":
        return Art;
      case "art2":
        return Art2;
      case "clean-code":
        return CleanCode;
      case "freud":
        return Freud;
      case "steve":
        return SteveJobs;
      case "macintosh1":
        return Macintosh1;
      case "macintosh2":
        return Macintosh2;
      case "macintosh3":
        return Macintosh3;
      case "imac":
        return imac;
      case "autoteks1":
        return Autoteks1;
      case "autoteks2":
        return Autoteks2;
      case "autoteks3":
        return Autoteks3;
      case "autoteks4":
        return Autoteks4;
      case "autoteks5":
        return Autoteks5;
      case "autoteks6":
        return Autoteks6;
      case "autoteks7":
        return Autoteks7;
      case "autoteks8":
        return Autoteks8;
      case "autoteks9":
        return Autoteks9;
      case "autoteks-landing1":
        return AutoteksLanding1;
      case "autoteks-landing-mac":
        return AutoteksLandingMac;
      case "diet1":
        return Diet1;
      case "diet2":
        return Diet2;
      case "diet3":
        return Diet3;
      case "diet4":
        return Diet4;
      case "diet5":
        return Diet5;
      case "diet6":
        return Diet6;
      case "diet7":
        return Diet7;
      case "diet8":
        return Diet8;
      case "diet9":
        return Diet9;
      case "diet10":
        return Diet10;
      case "coindom1": //macbook
        return Coindom1Mac;
      case "coindom2": //macbook
        return Coindom2Mac;
      case "coindom3":
        return Coindom3;
      case "coindom4":
        return Coindom4;
      case "coindom5":
        return Coindom5;
      case "coindom6":
        return Coindom6;
      case "coindom7":
        return Coindom7;
      case "coindom8":
        return Coindom8;
      case "coindom9":
        return Coindom9;
      case "coindom10":
        return Coindom10;
      case "coindom11":
        return Coindom11;
      case "coindom12":
        return Coindom12;

      case "formula-11":
        return Formula11;
      case "formula-12":
        return Formula12;
      case "formula-13":
        return Formula13;
      case "formula-14":
        return Formula14;
      case "formula-15":
        return Formula15;
      case "formula-16":
        return Formula16;
      case "formula-17":
        return Formula17;
      case "formula-18":
        return Formula18;
      case "formula-19":
        return Formula19;
      case "formula-110":
        return Formula110;
      case "formula-111":
        return Formula111;
      case "formula-112":
        return Formula112;
      case "formula-113":
        return Formula113;
      case "formula-114":
        return Formula111;

      case "f1card":
        return f1card;
      case "guessmynumber":
        return guessmynumber;
      case "guessmynumber1":
        return guessmynumber1;
      case "guessmynumber2":
        return guessmynumber2;
      case "guessmynumber3":
        return guessmynumber3;
      case "hangman":
        return hangman;
      case "hangman1":
        return hangman1;
      case "hangman2":
        return hangman2;
      case "newsreact":
        return newsreact;
      case "news1":
        return newsreact1;
      case "news2":
        return newsreact2;
      case "news3":
        return newsreact3;
      case "news4":
        return newsreact4;
      case "news5":
        return newsreact5;
      case "news6":
        return newsreact6;
      case "news7":
        return newsreact7;
      case "news8":
        return newsreact8;
      case "news9":
        return newsreact9;
      case "news10":
        return newsreact10;
      case "newsreactnative":
        return newsreactnative;
      case "news-rn1":
        return newsreactnative1;
      case "news-rn2":
        return newsreactnative2;
      case "news-rn3":
        return newsreactnative3;
      case "news-rn4":
        return newsreactnative4;
      case "news-rn5":
        return newsreactnative5;
      case "news-rn6":
        return newsreactnative6;

      default:
        return null;
    }
  }
}

export default AppImages;
