import React from "react";
import { useTranslation } from "react-i18next";

import MainNavigation from "@components/MainNavigation/MainNavigation";

import { motion } from "framer-motion";

import AppImages from "src/constants/assetManager/AppImages";
import AppIcons from "@constants/assetManager/AppIcons";

import "@components/TopBar/TopBar.scss";

const UK = AppIcons.getIcon("uk");
const DE = AppIcons.getIcon("de");
const URGLogo = AppImages.getImage("logo");

const TopBar = () => {
  const { t, i18n } = useTranslation();

  console.log(i18n);

  const languageButtonStyle = (language) => {
    return i18n.language === language
      ? "language-button-active"
      : "language-button";
  };

  const changeLanguageHandler = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <motion.div
      className="logo-container"
      initial={{ y: -250 }}
      animate={{ y: -10 }}
      transition={{
        delay: 1,
        type: "spring",
        stiffness: 100,
        damping: 20,
        mass: 1,
      }}
    >
      <img className="logo" src={URGLogo} alt="urg-logo" />
      <MainNavigation />
      <div className="button-wrapper">
        <div className="language-button-container">
          <img
            onClick={() => changeLanguageHandler("de")}
            className={languageButtonStyle("de")}
            src={DE}
            alt="de-flag"
          />
          <img
            onClick={() => changeLanguageHandler("en")}
            className={languageButtonStyle("en")}
            src={UK}
            alt="uk-flag"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default TopBar;
