import React from "react";

import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

import "@components/MainNavigation/MainNavigation.scss";

const MAIN_NAVIGATION = [
  { to: "welcome", text: "navigation.home", duration: 500 },
  { to: "about-me", text: "navigation.about", duration: 500 },
  { to: "projects", text: "navigation.projects", duration: 1000 },
  { to: "contact", text: "navigation.contact", duration: 1500 },
];

const MainNavigation = () => {
  const { t } = useTranslation();

  return (
    <nav className="navigation-container">
      {MAIN_NAVIGATION.map((link, index) => (
        <Link
          key={index}
          activeClass="active"
          className="link"
          to={link.to}
          spy={true}
          smooth={true}
          duration={link.duration}
        >
          {t(link.text)}
        </Link>
      ))}
    </nav>
  );
};

export default MainNavigation;
