import { getImagePath } from "@utils/images.service";
import { getIconPath } from "@utils/icons.service";

export const ABOUT_ME_ARTICLES = [
  {
    title: "Infinite Code Realms",
    text: "I am an aspiring developer skilled in building websites and web/mobile applications. My skill set, which includes a solid foundation in JavaScript, is always-expanding. Currently, I'm sharpening my React Native and Node.js skills.",
    image: getImagePath("js-mug"),
  },
  {
    title: "Beyond Code Lines",
    text: "I approach coding as more than just writing lines of code, I strive to understand the inner workings of how my code works behind the scenes. With a dedication to high standards, I consistently strive to deliver my best in every project I undertake.",
    image: getImagePath("js"),
  },
  {
    title: "Soft-skills",
    text: "In addition to my technical skills, I am known for my strong work ethic, adaptability, and effective communication. These qualities have enabled me to collaborate seamlessly with cross-functional teams on various projects, resulting in successful outcomes.",
    image: getIconPath("plato"),
  },
];

export const ABOUT_ME_CARDS = [
  {
    title: "Perfectionism in Engineering: Inspired by Steve Jobs' Legacy",
    image: getImagePath("steve"),
    text: "Steve Jobs' dedication to blending functionality with sleek design reshaped the tech world. His insistence on rigorous craftsmanship in every aspect of a product has always inspired me. Jobs' philosophy teaches that true innovation lies in creating devices that are as beautiful as they are functional. Jobs' brutal pursuit of perfection in both form and function significantly influenced me.",
  },
  {
    title: "Dancing Between Engineering and Art for Your Projects",
    image: getImagePath("art"),
    text: "The seamless integration of engineering precision with artistic expression. It concretes the pursuit of a balance where functionality meets creativity, transforming each effort from simple utility into an extraordinary work of art. With every project, I aim to elevate the simple, merging innovation with aesthetic appeal to achieve a state-of-the-art masterpiece.",
  },

  {
    title: "Engineering Excellence with a Focus on Clean Code",
    image: getImagePath("clean-code"),
    text: "This principle underscores the importance of rigorous engineering practices with a strong emphasis on writing clean, understandable, and maintainable code. It's about prioritizing code quality as much as functionality, ensuring that every project not only meets its technical objectives but also sticks to high standards of coding craftsmanship. This approach leads to robust, efficient, and scalable solutions, demonstrating a commitment to excellence in every line of code",
  },
  {
    title: "Code Unveiled: Exploring the Depths with a Psychoanalyst's Eye",
    image: getImagePath("freud"),
    text: "Coding with the depth and insight of a psychoanalyst, this approach delves into the underlying logic and motivations of software development. It's about unraveling the complexities of code, understanding the developer's thought process, and getting deeper exploration behind the scenes of programming. This method enhances problem-solving and connects deeply with the art of coding.",
  },
  /* { title: "The Hidden Layers of Code: A Psychoanalyst's Eye", image: Freud }, */
  {
    title: "Code at the Speed of Schumacher: Towards Technical Brilliance",
    image: getImagePath("schumacher"),
    text: "Drawing inspiration from Michael Schumacher's legendary speed, this method focuses on coding swiftly and efficiently without sacrificing precision. It emphasizes the importance of agility in development while maintaining a keen eye for detail and technical accuracy. This approach aims to achieve exceptional technical brilliance, mirroring Schumacher's skillful balance of velocity and control in every aspect of coding.",
  },
];

export const PROJECTS = [
  {
    logo: getIconPath("diet-genius"),
    title:
      "diet-genius: Nutrition Management and Meal Planning Software for Dietitians",
    website: getImagePath("diet2"),
    app: getImagePath("diet4"),
    intro:
      "diet-genius is a nutrition software designed for dietitians to manage their clients' dietary needs.",
    info: "It offers tools to create personalized meal plans, monitor clients' dietary progress, and maintain communication with clients. The app streamlines the process of nutrition counseling by allowing professionals to track health data, set goals, and provide personal dietary advice, all in one platform, enhancing the overall efficiency of nutritional care.",
    images: { count: 10, key: "diet" },
    keyFeatures: {
      "Personalized Meal Plans":
        "diet-genius equips dietitians with the ability to create highly personalized meal plans that cater to the unique dietary requirements and preferences of each client. Through an intuitive interface, dietitians can customize meal plans to address specific health goals, dietary restrictions, and nutritional needs.",
      "Dietary Progress Monitoring":
        "The software offers real-time tracking capabilities, allowing dietitians to monitor their clients' dietary progress with precision. It provides insightful data visualization tools to track changes in weight, dietary habits, and health indicators over time, facilitating data-driven decision-making.",
      "Client Communication Tools":
        "Effective communication is key to successful nutrition counseling. diet-genius integrates robust communication features that enable dietitians to maintain seamless contact with their clients. Through secure messaging and video conferencing, professionals can offer guidance, answer questions, and provide ongoing support.",
      "Health Data Tracking":
        "Dietitians can effortlessly track essential health data such as body measurements, blood pressure, cholesterol levels, and more within the platform. The software offers the convenience of centralizing all relevant client data in one secure location, simplifying the assessment and analysis process.",
      "Goal Setting":
        "Setting achievable health and dietary goals is crucial for clients on their wellness journeys. diet-genius facilitates the establishment of clear and measurable goals, helping clients and dietitians work collaboratively towards achieving desired outcomes.",
      "Personalized Dietary Advice":
        "Leveraging advanced algorithms and nutritional expertise, diet-genius generates personalized dietary recommendations based on individual client profiles. Dietitians can provide clients with tailored advice, ensuring that dietary plans align with their health objectives.",
    },
    summary:
      "diet-genius is an innovative software tool designed for dietitians, offering personalized meal planning, dietary progress monitoring, client communication tools, health data tracking, goal setting, and personalized dietary advice. Its intuitive interface and comprehensive features empower dietitians to deliver tailored nutritional guidance and track client progress effectively, enhancing the overall dietetic counseling experience.",
    ongoing: true,
  },
  {
    logo: getIconPath("autoteks"),
    title:
      "AutoTeks: Factory Shift and Workforce Management for Efficient Production",

    titleLogo: getIconPath("autoteks-title"),
    website: getImagePath("autoteks-landing-mac"),
    app: getImagePath("autoteks1"),
    intro:
      "AutoTeks is a web application developed primarily for comprehensive shift and workforce management, designed ot streamline the production process in a factory setting.",
    info: "AutoTeks provides a robust platform for effortlessly tracking and managing employee data, product information, and shift schedules in a user-friendly manner. Rich graphical representations, powered by Chart. js, enhance data visualization. End-to-End Production Process Optimization: The application is tailored to optimize the entire production proces within a factory, ensuring seamless operations from start ot finish.",
    images: { count: 9, key: "autoteks" },
    keyFeatures: {
      "Employee, Product, and Shift Tracking":
        "AutoTeks offers a robust platform for effortlessly tracking and managing employee data, product information, and shift schedules in a user-friendly manner. With rich graphical representations powered by Chart.js, it enhances data visualization, making it easier to manage and understand complex data sets.",

      "End-to-End Production Process Optimization":
        "The application is designed to optimize the entire production process within a factory, ensuring seamless operations from start to finish. Its comprehensive suite of tools enables efficient workflow management, minimizes production downtime, and maximizes output efficiency.",

      "Real-Time Reporting and Analytics":
        "AutoTeks provides real-time reporting and analytics features, enabling managers to make informed decisions based on up-to-date data. This functionality includes the tracking of key performance indicators (KPIs) and generation of detailed reports on productivity, efficiency, and resource allocation.",

      "Automated Scheduling and Resource Allocation":
        "The application streamlines scheduling and resource allocation, using intelligent algorithms to ensure optimal workforce utilization. It can automatically generate shift patterns, assign tasks based on skill sets, and adjust resources in response to changing demands.",

      "Integrated Communication Tools":
        "AutoTeks integrates advanced communication tools, facilitating effective coordination among team members. Features like instant messaging, notifications, and team collaboration spaces help in maintaining clear and consistent communication across all levels of the organization.",

      "Customizable Workflow Management":
        "Offering customizable workflow management options, AutoTeks allows factories to tailor the software to their specific processes and requirements. This flexibility ensures that the application can adapt to various operational needs, enhancing overall productivity and efficiency.",
    },
    summary:
      " AutoTeks is a sophisticated web application focused on comprehensive shift and workforce management in a factory setting. It features robust tracking systems for employees, products, and shifts, and optimizes the end-to-end production process. The application integrates real-time analytics, automated scheduling, effective communication tools, and customizable workflow management, all aimed at streamlining factory operations and boosting productivity.",
  },
  {
    logo: getIconPath("ethereum"),
    title: "Coindom: Cryptocurrency Tracking and NFT Marketplace",
    website: getImagePath("coindom2"),
    app: getImagePath("coindom5"),
    intro:
      "Coindom is a personal web development project that features real-time cryptocurrency data tracking and a simulated NFT marketplace, developed solely for practice purposes.",
    info: "Coindom offers cryptocurrency traders the ability to monitor up-to-the-minute market prices. Users can track daily, monthly, yearly trends, and even customize date ranges, visualizing data through dynamic Chart.js graphs. Coindom simulates an NFT marketplace, providing users with the ability to explore, filter, and add NFTs ot their carts. They can make payments ni the cryptocurrency of their choice or USD, providing a seamless and user-centric experience.",
    images: { count: 12, key: "coindom" },
    keyFeatures: {
      "Real-Time Cryptocurrency Data":
        "Coindom offers cryptocurrency enthusiasts the ability to monitor real-time market prices. It enables users to track daily, monthly, and yearly trends in cryptocurrency markets. Users can also customize date ranges for more specific analyses. The application leverages dynamic Chart.js graphs to visualize this data, providing an intuitive and interactive experience for tracking market movements.",

      "Simulated NFT Marketplace":
        "Coindom simulates an NFT marketplace environment, allowing users to explore a variety of NFTs, apply filters for precise searching, and add NFTs to their virtual carts. The simulation includes features for making payments in the user’s choice of cryptocurrency or USD. It's important to note that this feature is for practice and demonstration purposes only, using dummy data to simulate transactions. All NFTs and transactions within Coindom are entirely simulated and not linked to any real-world trading.",

      "Customizable User Dashboards":
        "The platform offers customizable dashboards, enabling users to create a personalized interface that suits their tracking preferences. This feature includes the ability to select and arrange widgets displaying different cryptocurrency data, NFT collections, and market trends.",

      "Interactive Learning Tools":
        "Recognizing the educational aspect of the project, Coindom includes interactive learning tools to help users understand the basics of cryptocurrencies and NFTs. These tools are designed to be engaging and informative, catering to both beginners and experienced individuals in the crypto world.",

      "Portfolio Management Simulation":
        "Users can simulate portfolio management by adding different cryptocurrencies and NFTs to their virtual portfolio. This feature allows users to experiment with portfolio diversification and risk management strategies in a risk-free environment, using simulated market data.",

      "Community Engagement Features":
        "To enhance the learning and engagement experience, Coindom integrates community features where users can share insights, discuss market trends, and collaborate on virtual projects. These features aim to foster a community of learners and enthusiasts around the fascinating world of cryptocurrencies and NFTs.",
    },
    summary:
      "Coindom is a personal web development project that features real-time cryptocurrency data tracking and a simulated NFT marketplace. Designed for practice purposes, it provides up-to-minute market trends visualization using Chart.js and simulates an NFT marketplace where users can explore and interact with dummy NFTs. This project serves as a learning tool for understanding cryptocurrency markets and NFT trading dynamics in a risk-free environment.",
  },
];

export const PROJECT_CARDS = [
  {
    key: "f1card",
    title: "Formula 1",
    images: { count: 14, key: "formula-1" },
    keyFeatures: {
      "Real-Time Formula 1 Data":
        "Designed to provide Formula 1 enthusiasts with up-to-the-minute information, the application seamlessly fetches data from a REST API. It offers comprehensive details, including current team and driver information, race schedules, track insights, dummy news updates, and driver-team standings.",

      "User-Friendly Interface":
        "The project focuses on user experience, making it easy for Formula 1 fans to access the latest information in a simple and visually attractive way.",

      "Interactive Race Track Analysis":
        "Features interactive maps and statistics for Formula 1 race tracks, providing fans with detailed insights into each circuit's layout and historical data.",

      "Driver and Team Profiles":
        "Includes in-depth profiles for each driver and team, offering career stats, recent performances, and personal info to give fans a comprehensive view of the sport's participants.",

      "Live Race Updates":
        "Delivers live updates during races, including lap times, position changes, and pit stop strategies, keeping fans connected to the action as it happens.",

      "Community Forum":
        "A dedicated space for fans to engage in discussions about races, teams, and drivers, fostering a vibrant community of Formula 1 enthusiasts.",

      "Personalized Notifications":
        "Enables users to receive tailored notifications about their favorite drivers, teams, or upcoming races, ensuring they stay informed about key events.",
    },
    summary:
      "The F1 Web App is a personal project marking an initial foray into React-based web development. It's a user-friendly platform providing real-time Formula 1 data and features aimed at engaging enthusiasts of the sport. The application includes a variety of features like detailed race data, a user-friendly interface, driver and team profiles, live updates, and a community forum, all designed to enhance the Formula 1 fan experience.",
  },
  {
    key: "guessmynumber",
    title: "Guess My Number",
    images: { count: 3, key: "guessmynumber" },
  },
  { key: "hangman", title: "Hangman", images: { count: 2, key: "hangman" } },
  {
    key: "newsreact",
    title: "News App - case study",
    images: { count: 10, key: "news" },
  },
  /* { key: "newsreactnative", title: "News App", reactNative: true }, */
];
