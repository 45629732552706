import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./routes";

import { I18nextProvider } from "react-i18next";

const App = () => {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
};

export default App;
