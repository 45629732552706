import React from "react";

import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

import { getIconPath } from "@utils/icons.service";
import InViewAnimation from "@components/in-view-animation/InViewAnimation";
import LottiePlayer from "@components/LottiePlayer/LottiePlayer";

import AppAnimations from "@constants/assetManager/AppAnimations";
import macintosh from "@assets/gifs/macintosh-hello.gif";

import "@pages/Welcome/Welcome.scss";
import { getImagePath } from "@utils/images.service";

import { motion } from "framer-motion";

import bg2 from "@assets/images/background/background2.svg";
import bg4 from "@assets/images/background/background4.svg";

const Scroll = AppAnimations.getAnimation("scroll");

const scrollDownStyles = {
  width: "120px",
  height: "120px",
};

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="welcome-container">
        <InViewAnimation>
          <div className="image-container">
            <img
              src={macintosh}
              /* src={getImagePath("macintosh2")} */
              /* src={getImagePath("imac")} */
              alt="macintosh1"
              className="macintosh"
            />
          </div>
        </InViewAnimation>
        <InViewAnimation
          variants={{
            hidden: { opacity: 0, y: 175 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 1.5, delay: 0.3 }}
        >
          <ReactSVG
            src={getIconPath("curly-brace")}
            className="curly-brace-icon"
          />
          <ReactSVG src={getIconPath("virus")} className="virus-icon" />
          <ReactSVG src={getIconPath("engineer")} className="engineer-icon" />
          <ReactSVG src={getIconPath("cloud")} className="cloud-icon" />
          <ReactSVG src={getIconPath("planet")} className="planet-icon" />
          <ReactSVG src={getIconPath("lego")} className="lego-icon" />
          <ReactSVG
            src={getIconPath("responsive")}
            className="responsive-icon"
          />
          <ReactSVG src={getIconPath("maximize")} className="maximize-icon" />
        </InViewAnimation>

        <div className="info">
          <div className="typewriter">
            {/*  <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .pauseFor(2000)
                  .typeString("¡Welcome!")
                  .pauseFor(2000)
                  .deleteAll()
                  .typeString("¡Wilkommen!")
                  .pauseFor(2000)
                  .deleteAll()
                  .typeString("¡Bienvenue!")
                  .pauseFor(2000)
                  .deleteAll()
                  .typeString("¡Bienvenido!")
                  .pauseFor(2000)
                  .deleteAll()
                  .typeString("¡Witamy!")
                  .pauseFor(2000)
                  .deleteAll()
                  .start();
              }}
              options={{
                loop: true,
              }}
            /> */}
            <p className="welcome">{t("welcome")}</p>
          </div>
          <motion.h2
            className="title"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.4 }}
          >
            {/*   {t("introduce")} <span className="first-letters">U</span>las{" "}
            <span className="first-letters">R</span>otinda{" "}
            <span className="first-letters">G</span>uler */}
            <span>{t("introduce")}</span> <span>Ulaş Rotinda Güler.</span>
          </motion.h2>
          <p className="about-me">Computer Engineer/Front-end Developer</p>
        </div>
        <InViewAnimation
          transition={{ duration: 0.5, delay: 1.7 }}
          variants={{
            hidden: { opacity: 0, y: -175 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <LottiePlayer animationData={Scroll} style={scrollDownStyles} />
        </InViewAnimation>
      </div>
    </>
  );
};

export default Welcome;
