import React, { Suspense, useState } from "react";

import { ReactSVG } from "react-svg";
import { getIconPath } from "@utils/icons.service";
import { getImagePath } from "@utils/images.service";
import { openGithub } from "@utils/utils.service";

import Loading from "@components/ui/loading/Loading";

const ProjectDetailsModal = React.lazy(() =>
  import("@components/projects/project-details/ProjectDetailsModal")
);

import "@components/projects/project-card/ProjectCard.scss";

const ProjectCard = ({ project }) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const { key, title, images, reactNative = false } = project;

  return (
    <Suspense fallback={<Loading small />}>
      <div className="card-wrapper" onClick={() => setIsDetailsModalOpen(true)}>
        <div className="project-card-container">
          <h2 className="project-title">{title}</h2>
          <img
            className={reactNative ? "project-image-iphone" : "project-image"}
            src={getImagePath(key)}
            alt="project-preview"
          />
        </div>
        <ReactSVG
          src={getIconPath("github2")}
          className="card-github-icon"
          onClick={openGithub}
        />
      </div>

      {isDetailsModalOpen && (
        <ProjectDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          project={project}
        />
      )}
    </Suspense>
  );
};

export default ProjectCard;
