import React, { useEffect, useRef } from "react";

import { motion, useInView, useAnimation } from "framer-motion";

const InViewAnimation = ({
  children,
  variants = {
    hidden: { opacity: 0, y: 75 },
    visible: { opacity: 1, y: 0 },
  },
  initial = "hidden",
  transition = { duration: 0.5, delay: 0.3 },
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <>
      <motion.div
        ref={ref}
        variants={variants}
        initial={initial}
        animate={mainControls}
        transition={transition}
      >
        {children}
      </motion.div>
    </>
  );
};

export default InViewAnimation;
