import React from "react";

import "@components/ui/card/article-card/ArticleCard.scss";
import InViewAnimation from "@components/in-view-animation/InViewAnimation";

const ArticleCard = ({ title, text, image }) => {
  return (
    <div className="information-wrapper">
      <InViewAnimation>
        <div className="img-wrapper">
          <img src={image} alt="js-mug" className="info-image" />
        </div>
      </InViewAnimation>
      <InViewAnimation>
        <div className="info-container">
          <h2 className="title">{title}</h2>
          <p className="information">{text}</p>
        </div>
      </InViewAnimation>
    </div>
  );
};

export default ArticleCard;
