import React from "react";

import { ReactSVG } from "react-svg";
import { getIconPath } from "@utils/icons.service";

import "@components/about-me/tech-stack/TechStack.scss";
import InViewAnimation from "@components/in-view-animation/InViewAnimation";

const TECH_STACK = [
  "javascript",
  "react",
  "redux",
  "git",
  "github",
  "html",
  "css",
  "sass",
];

const TechStack = () => {
  return (
    <div className="tech-stack-container">
      <div className="tech-stack-list">
        {TECH_STACK.map((key) => {
          return (
            <div key={key} title={key}>
              <InViewAnimation>
                <ReactSVG src={getIconPath(key)} className="tech-stack-icon" />
              </InViewAnimation>
            </div>
          );
        })}
        <InViewAnimation>
          <img
            src={getIconPath("styled")}
            alt="styled-components"
            className="styled"
            title="styled-components"
          />
        </InViewAnimation>
        <InViewAnimation>
          <img
            src={getIconPath("emotion")}
            alt="emotion"
            className="emotion"
            title="emotion"
          />
        </InViewAnimation>
      </div>
    </div>
  );
};

export default TechStack;
