import React from "react";
import { getImagePath } from "@utils/images.service";

import "@components/ui/loading/Loading.scss";

const Loading = ({ small = false }) => {
  return (
    <div className={small ? "small-loading-container" : "loading-container"}>
      <img src={getImagePath("logo")} alt="Loading..." className="logo" />
    </div>
  );
};

export default Loading;
