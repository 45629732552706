import React from "react";

import { Element } from "react-scroll";

import TopBar from "@components/TopBar/TopBar";
import Welcome from "@pages/Welcome/Welcome";
import MainNavigation from "@components/MainNavigation/MainNavigation";
import AboutMe from "@pages/AboutMe/AboutMe";
import LottiePlayer from "@components/LottiePlayer/LottiePlayer";
import AppAnimations from "@constants/assetManager/AppAnimations";
import Projects from "@pages/projects/Projects";
import Footer from "@components/Footer/Footer";

import "@pages/LandingPage/LandingPage.scss";

const Scroll = AppAnimations.getAnimation("scroll");

const scrollDownStyles = {
  width: "120px",
  height: "120px",
};

const LandingPage = () => {
  return (
    <div className="dark-mode-container">
      <TopBar />
      <Element name="welcome">
        <Welcome />
      </Element>
      <Element name="about-me">
        <AboutMe />
      </Element>
      <Element name="projects">
        <Projects />
      </Element>
      <Element name="contact">
        <Footer />
      </Element>
    </div>
  );
};

export default LandingPage;
