import Scroll from "@assets/animations/scroll.json";

class AppAnimations {
  static getAnimation(type) {
    switch (type) {
      case "scroll":
        return Scroll;

      default:
        return null;
    }
  }
}

export default AppAnimations;
