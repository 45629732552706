import React from "react";
import { useTranslation } from "react-i18next";

import Title from "@components/ui/title/Title";
import Project from "@components/projects/project/Project";
import ProjectCardList from "@components/projects/project-card/project-card-list/ProjectCardList";
import InViewAnimation from "@components/in-view-animation/InViewAnimation";

import { PROJECTS } from "@constants/data";

import "@pages/projects/Projects.scss";

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div className="projects-page-container">
      <InViewAnimation>
        <Title title="Projects" />
        <p className="subtitle">Some example of my intense work.</p>
      </InViewAnimation>
      {PROJECTS.map((project) => (
        <InViewAnimation key={project.intro}>
          <Project project={project} />
        </InViewAnimation>
      ))}
      <ProjectCardList />
    </div>
  );
};

export default Projects;
