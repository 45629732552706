import React from "react";

import InViewAnimation from "@components/in-view-animation/InViewAnimation";
import Title from "@components/ui/title/Title";
import AboutMeArticle from "@components/about-me/article/AboutMeArticle";
import TechStack from "@components/about-me/tech-stack/TechStack";
import AboutMeCard from "@components/about-me/card/AboutMeCard";

import { ABOUT_ME_CARDS } from "@constants/data";

import "@pages/AboutMe/AboutMe.scss";

const AboutMe = () => {
  return (
    <section className="about-me-container">
      <div className="about-me-wrapper">
        <InViewAnimation>
          <Title title="About me" />
        </InViewAnimation>
        <AboutMeArticle />
      </div>
      <div className="test">ULASROTINDAGULER</div>
      <div className="tech-stack">
        <TechStack />
      </div>
      <div className="card-list">
        <Title title="Code Craftsmanship" />
        <h2 className="subtitle">
          "Transforming ideas into reality through creative engineering."{" "}
        </h2>
        {ABOUT_ME_CARDS.map((card, index) => (
          <AboutMeCard
            key={card.title}
            index={index}
            title={card.title}
            image={card.image}
            text={card.text}
          />
        ))}
      </div>
    </section>
  );
};

export default AboutMe;
