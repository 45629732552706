import React from "react";

import InViewAnimation from "@components/in-view-animation/InViewAnimation";

import "@components/about-me/card/AboutMeCard.scss";

const AboutMeCard = ({ title, image, text, index }) => {
  const isOdd = index % 2 !== 0;

  return (
    <div className={`about-me-card-container ${isOdd ? "odd" : "even"}`}>
      <img src={image} alt="about-me" className="card-img" />
      <InViewAnimation>
        <div className="info-container">
          <h2 className="title">{title}</h2>
          <p className="info">{text}</p>
        </div>
      </InViewAnimation>
    </div>
  );
};

export default AboutMeCard;
