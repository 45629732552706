import React from "react";

import SocialButton from "@components/ui/social-button/SocialButton";

import { ReactSVG } from "react-svg";
import { getIconPath } from "@utils/icons.service";
import { getImagePath } from "@utils/images.service";

import "@components/Footer/Footer.scss";

const SOCIAL_LINKS = [
  { name: "linkedin", url: "https://www.linkedin.com/in/urg0/" },
  { name: "github2", url: "https://github.com/urg0" },
  { name: "medium", url: "https://medium.com/@urg0" },
];

const mail = "urgulerr@gmail.com";

const Footer = () => {
  const sendMail = () => {
    window.location.href = `mailto:${mail}`;
  };

  const openLink = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };
  return (
    <div className="footer-container">
      <button className="contact-button" onClick={sendMail}>
        <ReactSVG src={getIconPath("contact")} className="icon" />
        Contact me
      </button>
      <img src={getImagePath("logo")} alt="urg-logo" className="logo" />
      <h2 className="location">Istanbul</h2>
      <div className="social">
        {SOCIAL_LINKS.map(({ name, url }) => {
          return (
            <SocialButton
              key={name}
              name={name}
              onClick={() => openLink(url)}
            />
          );
        })}
      </div>
      <div className="author-container">
        <h2 className="author">© Ulaş Rotinda Güler</h2>
        <img src={getImagePath("memoji")} alt="urg" className="avatar" />
      </div>
    </div>
  );
};

export default Footer;
