import UK from "@assets/icons/uk.svg";
import DE from "@assets/icons/de.svg";
import Sun from "@assets/icons/sun.svg";
import Moon from "@assets/icons/moon.svg";
import angular from "@assets/icons/angular.svg";
import css from "@assets/icons/css.svg";
import git from "@assets/icons/git.svg";
import github from "@assets/icons/github.svg";
import html from "@assets/icons/html.svg";
import javascript from "@assets/icons/javascript.svg";
import react from "@assets/icons/react.svg";
import redux from "@assets/icons/redux.svg";
import sass from "@assets/icons/sass.svg";
import emotion from "@assets/icons/emotion.png";
import styled from "@assets/icons/styled-components.png";
import BusinessMann from "@assets/icons/businessman.svg";
import Plato from "@assets/icons/plato.svg";
import Github2 from "@assets/icons/github2.svg";
import Github3 from "@assets/icons/github3.svg";
import Linkedin from "@assets/icons/linkedin.svg";
import Medium from "@assets/icons/medium.svg";
import Contact from "@assets/icons/contact.svg";
import CurlyBrace from "@assets/icons/curly-brace.svg";
import Cloud from "@assets/icons/cloud.svg";
import Engineer from "@assets/icons/engineer.svg";
import Programming from "@assets/icons/programming.svg";
import Virus from "@assets/icons/virus.svg";
import Lego from "@assets/icons/lego1.svg";
import Planet from "@assets/icons/planet.svg";
import Responsive from "@assets/icons/responsive.svg";
import Maximize from "@assets/icons/maximize.svg";
import Ethereum from "@assets/icons/ethereum.svg";
import File from "@assets/icons/file.svg";
import Link from "@assets/icons/link.svg";
import Details from "@assets/icons/finger-details.svg";

import Autoteks from "@assets/icons/autoteks.svg";
import AutoteksTitle from "@assets/icons/autoteks-title.svg";
import DietGenius from "@assets/icons/diet-genius.svg";
import DietGeniusNoBg from "@assets/icons/diet-genius-no-bg.svg";
import Close from "@assets/icons/close.svg";

class AppIcons {
  static getIcon(type) {
    switch (type) {
      case "uk":
        return UK;
      case "de":
        return DE;
      case "moon":
        return Moon;
      case "sun":
        return Sun;
      case "angular":
        return angular;
      case "css":
        return css;
      case "git":
        return git;
      case "github":
        return github;
      case "html":
        return html;
      case "javascript":
        return javascript;
      case "react":
        return react;
      case "redux":
        return redux;
      case "sass":
        return sass;
      case "emotion":
        return emotion;
      case "styled":
        return styled;
      case "businessman":
        return BusinessMann;
      case "plato":
        return Plato;
      case "close":
        return Close;
      case "github2":
        return Github2;
      case "github3":
        return Github3;
      case "linkedin":
        return Linkedin;
      case "medium":
        return Medium;
      case "contact":
        return Contact;
      case "curly-brace":
        return CurlyBrace;
      case "cloud":
        return Cloud;
      case "engineer":
        return Engineer;
      case "programming":
        return Programming;
      case "virus":
        return Virus;
      case "lego":
        return Lego;
      case "planet":
        return Planet;
      case "responsive":
        return Responsive;
      case "maximize":
        return Maximize;
      case "ethereum":
        return Ethereum;
      case "file":
        return File;
      case "link":
        return Link;
      case "details":
        return Details;
      case "autoteks":
        return Autoteks;
      case "autoteks-title":
        return AutoteksTitle;
      case "diet-genius":
        return DietGenius;
      case "diet-genius-no-bg":
        return DietGeniusNoBg;

      default:
        return null;
    }
  }
}

export default AppIcons;
