import React from "react";

import { getIconPath } from "@utils/icons.service";
import { ReactSVG } from "react-svg";

import "@components/ui/social-button/SocialButton.scss";

const SocialButton = ({ name, onClick }) => {
  return (
    <ReactSVG
      src={getIconPath(name)}
      className={`${name}-icon`}
      onClick={onClick}
    />
  );
};

export default SocialButton;
