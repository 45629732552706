export const getAllImageKeys = (key, count) => {
  const imageKeys = Array.from(
    { length: count },
    (_, index) => `${key}${index + 1}`
  );

  return imageKeys;
};

export const openGithub = () => {
  window.open("https://github.com/urg0", "_blank", "noopener noreferrer");
};
